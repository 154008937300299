import {
  sendCustomerRequest
} from '@/api/common';
import URL from '@/basUrl';

const actions = {
  getRecords: async (context, { vm, params, loading }) => {
    await context.commit('SET_FILTER', params);
    await context.commit('SHOW_LOADING_SPINNER', false, { root: true });

    await sendCustomerRequest(URL.RECORDS_LIST, JSON.stringify(params))
      .then((result) => {
        // vm.$bvToast.show('danger-toast'); - toast bootstrap показывает юзеру оповещение с ошибкой
        const status = [200, 401, 404];
        if (!status.includes(result.status)) {
          context.commit('notifications/SAVE_MESSAGE', JSON.parse(result.text).Error, {
            root: true
          });
          vm.$bvToast.show('danger-toast');
          return;
        }

        if (result.status === 401) {
          context.commit('SHOW_RECORDS', { Error: result.text });
          context.commit('notifications/SAVE_MESSAGE',
            result.text, {
              root: true
            });
          vm.$bvToast.show('danger-toast');
          return;
        }

        context.commit('SHOW_RECORDS', JSON.parse(result.text));
      })
      .catch((e) => {
        console.error(e);
        context.commit('notifications/SAVE_MESSAGE', e, {
          root: true
        });
        vm.$bvToast.show('danger-toast');
      });

    if (!loading) context.commit('SHOW_LOADING', loading, { root: true });
  }
};

export default {
  actions
};
