import URL from '../basUrl';

// Блок параметров запросов
export const setPostOptions = (dataRow) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: dataRow
  };
  return options;
};

export const setPostOptionsWithCookie = (dataRow) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: dataRow,
    credentials: 'include'
  };
  return options;
};

export const setPostOptionsWithFile = (dataRow) => {
  const options = {
    method: 'POST',
    body: dataRow,
    credentials: 'include'
  };
  return options;
};

export const setPostOptionsDelete = (dataRow) => {
  const options = {
    method: 'DELETE',
    body: dataRow,
    credentials: 'include'
  };
  return options;
};

export const getOptionsWithCookie = () => {
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'aplications/json'
    }
  };
  return options;
};

// Блок запросов
export const checkServices = async () => {
  const response = await fetch(URL.ACTIVE_SERVICES, getOptionsWithCookie());
  return response.json();
};

export const getServiceData = async (url) => {
  const response = await fetch(url, getOptionsWithCookie());
  if (typeof response === 'boolean') return response;
  // Проверяет ответ сервера на возможность считать Json из ответа сервера
  return response.clone().text().then((res) => (res.length > 0 && res !== 'Пользователь не подключен. Возможно просрочена сессия.' ? response.json() : response.text()));
};

export const getDetailsWithQuery = async (url, query) => {
  const response = await fetch(`${url}?${query}`, getOptionsWithCookie());
  if (response.status === 403) return response.status;
  if (response.status === 404) {
    return {
      status: response.status,
      data: await response.text()
    };
  }
  return {
    status: response.status,
    data: await response.json()
  };
};

export const sendCustomerRequest = async (url, row) => {
  const response = await fetch(url, setPostOptionsWithCookie(row));
  return ({
    text: await response.text(),
    status: response.status
  });
};

export const sendCustomerRequestDelete = async (url, row, query = '') => {
  const response = await fetch(`${url}${query}`, setPostOptionsDelete(row));
  return ({
    text: await response.text(),
    status: response.status
  });
};

export const sendCustomerRequestWithFile = async (url, row) => {
  const response = await fetch(url, setPostOptionsWithFile(row));
  return {
    status: response.status,
    text: await response.text()
  };
};

export const downloadFile = async (url, params) => {
  const response = await fetch(url, setPostOptionsWithCookie(params));
  return ({
    status: response.status,
    text: await response.blob()
  });
};

export const signInUser = async (url, row) => {
  const response = await fetch(url, setPostOptionsWithCookie(row));
  if (response.ok || response.status === 400) {
    return {
      status: response.status,
      data: await response.json()
    };
  }
  return {
    status: response.status,
    data: await response.text()
  };
};

export const checkIsAuthorized = async () => {
  const response = await fetch(`${URL.AUTH}login`, getOptionsWithCookie());
  return response;
};
