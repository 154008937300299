import store from '../store';

// Маршруты для страниц
const mainLayout = {
  path: '/',
  name: 'LayoutMain',
  meta: {
    auth: true
  },
  title: 'Online Logic',
  redirect: '/reports/calls',
  children: [
    {
      path: '/my-numbers',
      name: 'Numbers',
      props: {
        default: true
      },
      meta: {
        title: 'Мои номера'
      },
      component: () => import('../views/Vpbx/TheVirtualOffice')
    },
    {
      path: '/my-lines',
      name: 'Lines',
      props: {
        default: true
      },
      meta: {
        title: 'Мои линии'
      },
      component: () => import('../views/Vpbx/TheVirtualPbx')
    },
    {
      path: '/Vpbx/common-info',
      name: 'VpbxMain',
      meta: {
        title: 'Виртуальная АТС'
      },
      component: () => import('../views/Vpbx/TheVpbxMain')
    },
    {
      path: '/Vpbx/option',
      name: 'OptionRequest',
      meta: {
        access: 'OPTIONGET',
        roles: ['8216083'],
        title: 'Состояние опции'
      },
      props: {
        default: true
      },
      component: () => import('../views/Vpbx/TheOptionsRequest')
    },
    {
      path: '/Vpbx/records',
      name: 'CallsRecords',
      meta: {
        access: 'CRECRDSGET',
        roles: ['8216083', '8259844'],
        title: 'Записи разговоров'
      },
      component: () => import('../views/Vpbx/TheCallRecord')
    },
    {
      path: '/org-details',
      name: 'OrgDetails',
      meta: {
        access: 'ORGDETAILS',
        roles: ['8216083'],
        title: 'Реквизиты'
      },
      component: () => import('../views/org_details/TheOrgDetails')
    },
    {
      path: '/services/call-tracking',
      name: 'CallTracking',
      meta: {
        access: 'CALLTRACKGET',
        roles: ['8216083', '8216084'],
        title: 'Колл трекинг'
      },
      component: () => import('../views/Services/Calltracking/TheCallTracking')
    },
    {
      path: '/services/call-tracking/edit',
      name: 'CalltrackingRuleEdit',
      meta: {
        access: 'UCALLTRACK',
        roles: ['8216083'],
        title: 'Настроить правило'
      },
      props: {
        default: true
      },
      component: () => import('../views/Services/Calltracking/TheCalltrackingRuleEdit')
    },
    {
      path: '/services/conference-call',
      name: 'ConferenceCall',
      meta: {
        access: 'CONFERENCE',
        roles: ['8216083'],
        title: 'Конференц-связь'
      },
      component: () => import('../views/Services/сonference_сall/TheConferenceCall')
    },
    {
      path: '/onLo-phone',
      meta: {
        access: 'ONLOPHONEGET',
        roles: ['8216083', '8216084', '8216085'],
        title: 'OnLo Phone'
      },
      component: () => import('../views/Services/TheOnloPhone')
    },
    {
      path: '/services/intercity',
      name: 'InterCity',
      meta: {
        access: 'TARIFF',
        roles: ['8216083'],
        title: 'Тарифы междугородней связи'
      },
      component: () => import('../views/Services/TheIntercityCall')
    },
    {
      path: '/services/callback',
      name: 'Callback',
      meta: {
        access: 'CALLBACKALL',
        roles: ['8216083'],
        title: 'Обратный звонок'
      },
      component: () => import('../views/Services/Callback/TheCallBack')
    },
    {
      path: '/services/callback/edit',
      name: 'EditCallbackRule',
      props: {
        default: true
      },
      meta: {
        access: 'CALLBACKSET',
        title: 'Редактировать правило обратного звонка'
      },
      component: () => import('../views/Services/Callback/TheEditRule')
    },
    {
      path: '/services/callback/create',
      name: 'CreateCallbackRule',
      props: {
        default: true
      },
      meta: {
        access: 'CALLBACKSET',
        title: 'Создать правило обратного звонка'
      },
      component: () => import('../views/Services/Callback/TheCreateRule')
    },
    {
      path: '/auto-dialer',
      name: 'AutoDialerList',
      meta: {
        access: 'ADIALLER',
        title: 'Голосовая рассылка'
      },
      component: () => import('../views/Services/Auto_dialer/TheAutoDialer')
    },
    {
      path: '/auto-dialer/details',
      name: 'AutoDialerDetails',
      component: () => import('../views/Services/Auto_dialer/TheDialerDetails'),
      props: {
        default: true
      },
      meta: {
        access: 'ADIALLER',
        roles: ['8216083'],
        title: 'Голосовая рассылка'
      }
    },
    {
      path: '/dialer-edit',
      name: 'DialerEdit',
      meta: {
        access: 'WADTASK',
        roles: ['8216083'],
        title: 'Редактирование голосовой рассылки'
      },
      props: {
        default: true
      },
      component: () => import('../views/Services/Auto_dialer/TheDialerEdit')
    },
    {
      path: '/reports',
      meta: {
        access: 'REPORTGET',
        roles: ['8216083', '8259844'],
        title: 'Отчеты'
      },
      props: {
        default: true
      },
      component: () => import('../views/reports/TheReports')
    },
    {
      path: '/reports/calls',
      meta: {
        access: 'TSTATGET',
        roles: ['8216083', '8216086', '8259844'],
        title: 'История вызовов'
      },
      component: () => import('../views/reports/TheCallsHistory')
    },
    {
      path: '/settings-all-profile',
      meta: {
        access: 'PROFILESSET',
        roles: ['8216083'],
        title: 'Настройки пользователей'
      },
      component: () => import('../views/TheSettingsUsers'),
      beforeEnter(to, from, next) {
        store.dispatch('profileSettings/readAllprofileAccount');
        next();
      }
    },
    {
      path: '/settings-profile',
      meta: {
        title: 'Настройки профиля'
      },
      component: () => import('../views/TheSettingsAccount')
    },
    {
      path: '/finance-documents',
      meta: {
        access: 'FINGET',
        title: 'Финансовые документы',
        roles: ['8216083']
      },
      component: () => import('@/views/TheFinanceDocuments')
    },
    {
      path: '/cri-connect',
      meta: {
        access: 'CRMSET',
        roles: ['8216083'],
        title: 'Подключить интеграцию'
      },
      component: () => import('../views/Services/Cti/TheCtiList')
    },
    {
      path: '/cri-my-connected',
      meta: {
        access: 'CRMGET',
        roles: ['8216083'],
        title: 'Мои интеграции'
      },
      component: () => import('../views/Services/Cti/TheCtiConnected')
    },
    {
      path: '/cri-description',
      name: 'CtiDescription',
      meta: {
        access: 'CRMGET',
        roles: ['8216083'],
        title: 'Возможности интеграции'
      },
      component: () => import('../views/Services/Cti/TheCtiDescription')
    },
    {
      path: '/work-in-progress',
      name: 'TheWorkInProgress',
      meta: {
        title: 'Раздел в разработке'
      },
      component: () => import('../views/TheWorkInProgress')
    },
    {
      path: '/text-to-speach',
      name: 'TextToSpeach',
      meta: {
        access: 'TTSGET',
        title: 'Преобразование текста в речь'
      },
      component: () => import('../views/Services/TheTextToSpeach')
    },
    {
      path: '/calls-analysis-reports',
      name: 'CallsAnalysisReport',
      meta: {
        access: 'ReportGet',
        roles: ['8216083'],
        title: 'Анализ звонков'
      },
      component: () => import('../views/reports/Call_analysis/TheCallsAnalysis'),
      children: [
        {
          path: 'service-metrics',
          name: 'ServiceMetrics',
          meta: {
            access: 'ReportGet',
            roles: ['8216083'],
            title: 'Оценка эффективности работы'
          },
          component: () => import('../views/reports/Call_analysis/sections/TheEfficiencyMark'),
          beforeEnter(to, from, next) {
            store.commit('SHOW_LOADING', false);
            next();
          }
        },
        {
          path: 'efficiency-mark',
          name: 'EfficiencyMark',
          meta: {
            access: 'ReportGet',
            roles: ['8216083'],
            title: 'Показатели обслуживания'
          },
          component: () => import('../views/reports/Call_analysis/sections/TheEfficiencyMark'),
          beforeEnter(to, from, next) {
            store.commit('SHOW_LOADING', false);
            if (!Object.keys(store.state.reports.module.table).length) {
              next();
              return;
            }
            store.commit('reports/CLEAR_TABLE');
            next();
          }
        }
      ]
    },
    {
      path: '/voice-bot',
      name: 'VoiceBot',
      meta: {
        title: 'Голосовой бот'
      },
      component: () => import('../views/Services/TheVoiceBot')
    },
    {
      path: '/create-statement',
      name: 'TheStatementCreation',
      meta: {
        access: 'STMENTSET',
        title: 'Создать заявку'
      },
      component: () => import('../views/statements/TheStatementCreation')
    },
    {
      path: '/reports-description',
      name: 'TheDescription',
      meta: {
        access: 'REPORT',
        title: 'Описание'
      },
      component: () => import('../views/TheDescription')
    },
    {
      path: '/report-details',
      name: 'ReportDetails',
      props: {
        default: true
      },
      meta: {
        access: 'REPORT',
        roles: ['8216083'],
        title: 'Подробности'
      },
      component: () => import('../views/reports/TheReportDetails')
    }
  ],

  component: () => import('../layout/TheLayoutMain')
};

export default mainLayout;
