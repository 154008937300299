const getters = {
  userNumbers: (state) => state.userNumbers,
  userLines: (state) => state.userLines,
  actualNumbers: (state) => state.actualNumbers,
  numbers: (state) => state.Numbers
};

export default {
  getters
};
