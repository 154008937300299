const state = {
  userNumbers: {},
  userLines: {},
  actualNumbers: {},
  Numbers: []
};

export default {
  state
};
