// eslint-disable-next-line import/prefer-default-export
export const mutations = {
  SHOW_LOADING: (state, loadingData) => {
    state.isLoading = loadingData;
  },

  SHOW_LOADING_SPINNER: (state, param) => {
    state.loadingReport = param;
  },

  SET_DATA: (state, data) => {
    state.htmlData = data;
  },

  MAKE_ACTIVE: (state, param) => {
    state.isActive = param;
  }
};
