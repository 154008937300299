const getters = {
  table: (state) => state.table,

  page: (state) => state.page,

  requestData: (state) => state.requestData,

  // формирует объект для bootstrap-table
  columns: (state) => {
    const {
      table
    } = state;
    let tableLength;
    if (Object.keys(table).length > 0) tableLength = Object.keys(table).length;
    if (tableLength && table.Columns) {
      const columnsName = Object.entries(table.Columns);
      return columnsName.map((el) => ({
        key: el[0],
        label: el[1],
        sortable: true
      }));
    }
    return [];
  },

  rows: (state, getter) => {
    const {
      table
    } = state;

    let rows;
    if (getter.columns.length > 0) {
      const columnsName = getter.columns.map((el) => el.key); // Получает названия колонок
      // Получает строки таблицы
      const base = table.Rows.map((el) => el.Values);
      // Формирует массив строк - колонка и её значение
      const rowsData = base.map((el) => el.map((i, index) => ([columnsName[index], i.Value])));
      // Получает настройку rowspan для td таблицы
      const rowsAttr = base.map((el) => el.map((i) => (i.Rowspan === 0 ? 1 : i.Rowspan)));
      // формирует объект из указанного массива
      const createObject = (item) => item.map((el) => Object.fromEntries(el));
      // формирует объект { строки, rowspan } для использования в таблице
      rows = {
        rowsData: createObject(rowsData),
        rowspan: rowsAttr
      };
      rows.rowsData.forEach((el, index) => {
        if (el.id && typeof el.id === 'string') rows.rowsData[index].id = JSON.parse(el.id);
        if (el.Line && typeof el.IncomingCount) {
          if (el.IncomingCount) rows.rowsData[index].IncomingCount = JSON.parse(el.IncomingCount);
          if (el.MissedCount) rows.rowsData[index].MissedCount = JSON.parse(el.MissedCount);
          if (el.OutgoingCount) rows.rowsData[index].OutgoingCount = JSON.parse(el.OutgoingCount);
        }
      });
      return rows;
    }
    return [];
  }
};

export default {
  getters
};
