import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  getServiceData,
  checkIsAuthorized
} from '@/api/common';
import URL from '@/basUrl';
import store from '../store';
import login from './login';
import mainLayout from './mainLayout';
import registration from './registration';
import {
  checkSession,
  roles,
  checkHasPermission,
  goToLoginEmpty,
  goToLoginWithReturn,
  checkUrl,
  checkAvailableServices
} from './helpers_guard';

Vue.use(VueRouter);

const routes = [
  mainLayout,
  login,
  registration,
  {
    path: '/404/:pagePart',
    name: '404',
    meta: {
      title: '404 Страница не найдена'
    },
    component: () => import('../views/NotFoundComponent.vue'),
    props: true
  },
  {
    path: '*',
    meta: {
      title: '404 Страница не найдена'
    },
    redirect: { name: '404' },
    component: () => import('../views/NotFoundComponent.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const orgName = 'Онлайн Лоджик';

router.beforeEach(async (to, from, next) => {
  // Проверяет наличие прав доступа у пользователя
  const hasPermissions = await checkHasPermission(to);
  // Изменяет заголовок вкладки в соответствии с названием страницы
  document.title = `${to.meta.title} | ${orgName.toUpperCase()}`;

  // проверяет авторизован ли пользователь
  let currentUser = '';
  if (!to.meta || !to.meta.allowAnonymous) {
    await checkIsAuthorized()
      .then((res) => {
        currentUser = res.ok;
      })
      .catch((e) => {
        console.error('Пользователь не авторизован:', e);
        next({ path: '/login' });
      });
    if (!currentUser) return;
  }

  // если список подключенных услуг не доступен, то получаем его заново
  // при этом исключаются адреса страниц указанных в fullPathUrl
  // const servicesData = Object.keys(store.getters['connectedServices/servicesData']);
  if (!checkUrl(to.path)) {
    // Получение всех подключенных услуг
    await getServiceData(URL.ACTIVE_SERVICES)
      .then((result) => {
        if (typeof result !== 'string') store.commit('connectedServices/SAVE_STATUS', result);
      })
      .catch((e) => console.error(e));
  }

  // Делает активным спиннер загрузки данных
  if (checkAvailableServices(to)) {
    store.commit('SHOW_LOADING', true);
  }

  /* Блок кода для возврата пользователя, по адресу, на котором пользователь находился или
   на который хотел перейти, когда сессия устарела.
   при изменении главной страницы нужно заменить to.fullPath !== 'новый адрес' */

  // Если сессия не активна и адрес - не главная страница, то перенаправляем на страницу логина.
  if (checkSession(to, currentUser) && to.fullPath !== '/reports/calls') {
    // Если установлены ограничения по правам для доступа на страницу и нет прав у пользователя
    if (roles(to) && !hasPermissions) {
      goToLoginEmpty(next);
      return;
    }
    goToLoginWithReturn(to, next);
    return;
  }

  // Если сессия не активна и адрес, с которого переход осуществляется - не главная страница -
  // перенаправляем на страницу логина. При входе в лк на главную страницу попадет пользователь
  if (checkSession(to, currentUser) && from.fullPath === '/') {
    goToLoginEmpty(next);
    return;
  }

  // При входе в лк проверяет есть ли у пользователя права на вход в главный раздел.
  // Если нет, то перенаправляет на тот, который доступен к просмотру
  if (roles(to) && checkUrl(from.fullPath)) {
    if (hasPermissions) {
      next();
    } else {
      router.push({
        name: 'VpbxMain'
      });
      return;
    }
  }

  // Проверяет установлены ли ограничения на просмотр/редактирование по правам пользователя
  if (roles(to) && !checkUrl(from.fullPath)) {
    // Если права есть - доступ открыт, переходит на адрес
    if (hasPermissions) {
      if (!checkAvailableServices(to)) {
        if (checkUrl(from.path)) {
          router.replace({
            name: 'VpbxMain'
          });
        }
        store.commit('notifications/SAVE_MESSAGE',
          'Отчеты доступны только для подключенных услуг');
        router.app.$bvToast.show('danger-toast');
        return;
      }

      next(checkAvailableServices(to));
      return;
    }
    // Если недостаточно прав у пользователя, блокирует переход и показывает оповещение с ошибкой
    next(false);
    store.commit('SHOW_LOADING', false);
    store.commit('notifications/SAVE_MESSAGE',
      'Недостаточно прав для просмотра/редактирования раздела');
    router.app.$bvToast.show('danger-toast');
    return;
  }

  next();
});

export default router;
