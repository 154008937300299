const mutations = {
  FORM_TRIMMING: (state, formData) => {
    const currentData = Object.assign(state.requestForm, formData);
    Object.keys(currentData).forEach((key) => (currentData[key] === null || currentData[key] === '' || currentData[key] === 'year') && delete currentData[key]); // удаляем ключи. которые null
    state.requestForm = currentData;
  }
};

export default {
  mutations
};
