const actions = {
  getCustomerNumbers: (context, customerNumbers) => context.commit('SAVE_CUSTOMER_NUMBERS',
    customerNumbers),

  getCustomerLines: (context, customerLines) => context.commit('SAVE_CUSTOMER_LINES',
    customerLines),

  addNumbers: (context, number) => {
    context.commit('WRITE_NUMBERS', number);
  },

  clearSelectedNumbers: (context) => {
    context.commit('CLEAR_NUMBERS');
  }

};

export default {
  actions
};
