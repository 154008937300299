import {
  getServiceData
} from '@/api/common';
import URL from '@/basUrl';

const actions = {
  getCompanyAndCustomerData: async (context, {
    vm,
    loading = false
  }) => {
    await getServiceData(URL.ORG_DETAILS)
      .then(async (result) => {
        // vm.$bvToast.show('danger-toast'); - toast bootstrap показывает юзеру оповещение с ошибкой
        if (result.Error) {
          context.commit('notifications/SAVE_MESSAGE', result.Error, {
            root: true
          });
          vm.$bvToast.show('danger-toast');
          return;
        }

        // Исполняется в случае верно отправленного запроса и 200 ответа сервера
        await context.commit('SET_USER', result.Customer);
        await context.commit('SET_COMPANY', result.Contractor);
        context.commit('SHOW_LOADING', loading, {
          root: true
        });
      })
      .catch((e) => {
        console.error(e);
        context.commit('notifications/SAVE_MESSAGE', e, {
          root: true
        });
        vm.$bvToast.show('danger-toast');
      });
  }
};

export default {
  actions
};
