const actions = {
  createCustomerData: (context, getter) => context.commit('WRITE_DATA', getter),

  setNewLogin: (context, loginData) => context.commit('SET_LOGIN_DATA', loginData),

  showBasePage: (context, booleanvalue) => context.commit('BASE_FROM', booleanvalue)
};

export default {
  actions
};
