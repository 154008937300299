const mutations = {
  GET_CALLS: (state, callsList) => {
    // eslint-disable-next-line valid-typeof
    if (callsList === '') {
      state.calls = [];
    } else {
      state.calls = { ...callsList };
    }
  }
};

export default {
  mutations
};
