import {
  getDetailsWithQuery
} from '@/api/common';
import URL from '@/basUrl';

const actions = {
  getDetails: async (context, query) => {
    await getDetailsWithQuery(URL.DIALER_DETAILS, `templateId=${query}`)
      .then((result) => context.commit('SAVE_DETAILS', result.data))
      .catch((e) => console.error(e));
  }
};

export default {
  actions
};
