import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import {
  mutations
} from './mutations';
import sideBarStore from './modules/sideBarStore/index';
import loginStore from './modules/loginStore/index';
import profileSettings from './modules/profileSettings/index';
import financeDocuments from './modules/financeDocuments/index';
import registration from './modules/registration/index';
import connectedServices from './modules/connectedServices/index';
import conference from './modules/conference/index';
import autoDialer from './modules/autoDialer/index';
import notifications from './modules/notifications/index';
import callRecords from './modules/callRecords/index';
import vpbx from './modules/vpbx/index';
import reports from './modules/reports/index';
import callsHistory from './modules/callsHistory/index';
import callsAnalysis from './modules/callsAnalysis/index';
import statements from './modules/statements/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: true,
    loadingReport: false,
    isActive: false,
    htmlData: [],
    requestData: {}
  },
  getters: {
    isLoading: (state) => state.isLoading,
    loadingReport: (state) => state.loadingReport,
    isActive: (state) => state.isActive,
    htmlData: (state) => state.htmlData
  },
  mutations,
  modules: {
    profileSettings,
    sideBarStore,
    loginStore,
    financeDocuments,
    registration,
    connectedServices,
    conference,
    autoDialer,
    notifications,
    vpbx,
    callRecords,
    reports,
    callsHistory,
    callsAnalysis,
    statements
  },

  plugins: [createPersistedState({
    storage: window.localStorage,
    paths: ['loginStore']
  })]
});
