const domain = 'https://api.onlinelogic.ru';

export default {
  AUTH: `${domain}/account/`,
  USERS: `${domain}/users`,
  BUTTERY: `${domain}/UserServices/GetOptionsBattery`,
  FINANCE_DOCUMENTS: `${domain}/FinanceDocuments`,
  NUMBERS_REGISTRATION: `${domain}/claim/GetActualNumbers`,
  CONFIRM_NUMBER: `${domain}/users/ConfirmNumber`,
  DATA_ORGANIZATIONS: `${domain}/claim/GetOrgByInn`,
  REGISTRATION: `${domain}/users/RegisterNewCustomerOneNumber`,
  SERVICES: `${domain}/UserServices/GetAvailableUserServices`,
  RESERVE_NUMBERS: `${domain}/Claim/ReserveNumbersForMe`,
  CHECK_PERMISSION: `${domain}/CalltrackingService/CheckPermits`,

  // запрос на получение списка элементов услуги ВАТС
  VIRTUAL_PBX: `${domain}/HostedPBXService/GetHostedPbxes`,

  // запрос на получение списка элементов услуги "Виртуальный офис"
  VIRTUAL_OFFICE: `${domain}/VirtualOfficeService/GetVirtualOffices`,

  // подключенные услуги возвращает
  ACTIVE_SERVICES: `${domain}/UserServices/GetActiveUserServices`,

  // запрос настроек услуги
  INTERCITY_CALL: `${domain}/CallingService/GetCallingServices`,
  ONLOPHONE_CALLBACK: `${domain}/OnloPhoneService/RequestOnloPhoneService`,

  // Голосовая рассылка
  AUTO_DIALER: `${domain}/AutoDiallerService/GetAutoDialerServices`,
  DIALER_DETAILS: `${domain}/AutoDiallerService/GetAutoDialerDetails`,
  START_DIALER: `${domain}/AutoDiallerService/RunAutoDialerTask`,
  STOP_DIALER: `${domain}/AutoDiallerService/StopAutoDialerTask`,
  UPDATE_DIALER: `${domain}/aas/UpdateAasTemplate`,
  GET_DIALER_DETAILS: `${domain}/aas/GetAasTemplates`,

  // Скачивание приложения Onlo Phone
  LINK_APP: `${domain}/OnloPhoneService/DownloadOnloPhone`,

  // Запрос на отображение опций ВАТС
  VPBX_OPTIONS: `${domain}/UserServices/GetOption`,

  // Запрос на подключение опций ВАТС и интеграций
  OPTION_CONNECT: `${domain}/UserServices/SendOptionRequest`,

  // CallTracking
  // запрос настроек услуги
  CALL_TRACKING: `${domain}/CalltrackingService/GetCalltrackingServices`,

  // Чтение настроек выбранного правила
  READ_RULE: `${domain}/CalltrackingService/CalltrackingRuleRead`,
  UPDATE_RULE: `${domain}/CalltrackingService/CalltrackingRuleUpdate`,
  CREATE_RULE: `${domain}/CalltrackingService/CalltrackingRuleCreate`,

  // Для кнопки запроса клиентом на обратный звонок
  CALL_BACK: 'https://api.stranzit.ru/v1/api/Callback/Create',

  // Записи диалогов
  CALL_RECORDS: `${domain}/CallRecords/Defaults`,
  RECORDS_LIST: `${domain}/CallRecords/GetCallRecords`,
  RECORD_DOWNLOAD: `${domain}/CallRecords/Download`,
  RECORD_DOWNLOAD_ALL: `${domain}/CallRecords/DownloadAllOnPage`,
  RECORD_PLAY: `${domain}/CallRecords/Play`,
  RECORD_CONNECTED: `${domain}/CallRecords/CallRecordServiceConnected`,

  // Реквизиты
  ORG_DETAILS: `${domain}/BankDetails/GetCustomerDetails`,

  // Межгород
  INTERCITY_LIST: `${domain}/CallingService/GetTariffList`,
  INTERCITY_TARIF: `${domain}/CallingService/GetTariffByArea`,

  // Интеграции
  CTI_LIST: `${domain}/ctiservice/List`,
  CTI_FORM: `${domain}/userservices/getoption?id=80`,

  // Отчеты
  REPORTS_LIST: `${domain}/GenericReports/GetReportTypesList`,
  REPORT: `${domain}/GenericReports/GetSelectedReport`,

  // История звонков
  CALLS_HISTORY: `${domain}/Report/GetTelephonyStatsData`,

  // Оценка эффективности работы
  SERVICE_INCOMING: `${domain}/Report/GetIncomingCalls`,
  SERVICE_OUTGOING: `${domain}/Report/GetOutgoingCalls`,
  SERVICE_MISSED: `${domain}/Report/GetMissedCalls`,

  // Показатели обслуживания
  EFFICIENCY_DAYS: `${domain}/Report/GetIpoCalls`,
  EFFICIENCY_DAYLY: `${domain}/Report/GetDailyLineCalls`,

  // Обращения пользователей
  STATEMENTS_LIST: `${domain}/Statements/GetStatementsList`,
  STATEMENTS_LAYOUT: `${domain}/Statements/FormStatementView`,
  STATEMENT_SEND: `${domain}/Statements/SendStatementView`,
  STATEMENT_PDF: `${domain}/Statements/DownloadStatementViewInPdf`,
  STATEMENT_SERVICES: `${domain}/Statements/GetServicesList`,

  // Голосовой бот
  VOICE_BOT: `${domain}/Claim/SendVoiceBotSetupClaim`
};
