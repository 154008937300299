import URL from '../../../basUrl';

const actions = {
  readAllprofileAccount: async ({ commit }) => {
    try {
      const response = await fetch(`${URL.USERS}/all`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'aplications/json'
        }
      });

      if (response.ok) {
        const users = await response.json();
        commit('changeUsers', users);
      }
    } catch (e) {
      console.error(e);
    }
  },

  addUser: async (_, user) => {
    const raw = JSON.stringify(user);

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: raw
    };

    await fetch('https://api.onlinelogic.ru/users', requestOptions);
  },

  delUser: async (_, idUser) => {
    await fetch(`${URL.USERS}?id=${idUser}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'aplications/json'
      }
    });
  },

  updateUser: async (_, user) => {
    const raw = JSON.stringify(user);
    const requestOptions = {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: raw
    };
    await fetch(`${URL.USERS}?id=${user.id}`, requestOptions);
  },

  readRoleUser: async ({ commit }) => {
    try {
      const res = await fetch('https://api.onlinelogic.ru/Roles', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'aplications/json'
        }
      });
      if (res.ok) {
        const role = await res.json();
        const selectRoles = role.map((item) => ({
          text: item.DisplayName,
          value: item.Id
        }));
        commit('changeUserRoles', selectRoles);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export default {
  actions
};
