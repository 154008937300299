import {
  sendCustomerRequest
} from '@/api/common';
import URL from '@/basUrl';

const actions = {
  getData: async (context, {
    vm = this,
    params,
    loading = false
  }) => {
    await sendCustomerRequest(URL.CALLS_HISTORY, JSON.stringify(params))
      .then((result) => {
        context.commit('CLEAR_TABLE');
        // vm.$bvToast.show('danger-toast'); - toast bootstrap показывает юзеру оповещение с ошибкой
        const status = [200, 401, 404];
        if (!status.includes(result.status)) {
          context.commit('notifications/SAVE_MESSAGE', JSON.parse(result.text).Error, {
            root: true
          });
          vm.$bvToast.show('danger-toast');
          return;
        }

        if (result.status === 401) {
          context.commit('SET_TABLE', { Error: result.text }, { root: true });
          context.commit('notifications/SAVE_MESSAGE',
            result.text, {
              root: true
            });
          vm.$bvToast.show('danger-toast');
          return;
        }
        // Исполняется в случае верно отправленного запроса и 200 ответа сервера
        context.commit('SET_TABLE', JSON.parse(result.text));
        context.commit('SHOW_LOADING_SPINNER', loading, { root: true });
      })
      .catch((e) => {
        console.error(e);
        context.commit('notifications/SAVE_MESSAGE', e, {
          root: true
        });
        vm.$bvToast.show('danger-toast');
      });
  }
};

export default {
  actions
};
