const mutations = {
  SET_TABLE: (state, table) => {
    state.table = table;
  },

  CLEAR_TABLE: (state) => {
    state.table = {};
  },

  SET_PAGE: (state, page) => {
    state.page = page;
  },

  CREATE_REQUEST_BODY: (state, row) => {
    state.requestData = row;
    state.requestData.PageNumber = state.page;
  }
};

export default {
  mutations
};
