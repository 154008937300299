const mutations = {
  WRITE_DATA: (state, customerData) => {
    // Create new Object with valid key:value
    // eslint-disable-next-line array-callback-return
    const entries = customerData.map((item) => [item.id, item.value]);
    state.regData.ContactData = Object.fromEntries(entries);
    state.regData.Inn = state.regData.ContactData.Inn;
    delete state.regData.ContactData.Inn; // delete Inn because he doesn't need in this object
    // Transform PhoneNumber from +7 (999) 999-99-99 to 79999999999
    state.regData.ContactData.PhoneNumber = state.regData.ContactData.PhoneNumber.replace(/\D/g, '')
      .substring(0, 11);
  },

  SET_LOGIN_DATA: (state, customerData) => {
    const entries = customerData.map((item) => [item.id, item.value]);
    const currentData = Object.fromEntries(entries);
    state.regData.Login = currentData.Login;
    state.regData.Password = currentData.Password;
    state.regData.PasswordConfirm = currentData.PasswordConfirm;
  },

  BASE_FROM: (state, status) => {
    state.isFirstPage = status;
  }
};

export default {
  mutations
};
