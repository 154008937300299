import state from './state';
import actions from './actions';

export default {
  namespaced: true,
  modules: {
    module: {
      ...state,
      ...actions
    }
  }
};
