import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import CoreuiVue from '@coreui/vue';
import Highcharts from 'highcharts';
import Stock from 'highcharts/modules/stock';
import HighchartsVue from 'highcharts-vue';
import exporting from 'highcharts/modules/exporting';
import { iconsSet as icons } from './assets/icons/icons';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '../node_modules/normalize.css/normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/scss/style.scss';

// Installing whole package

exporting(Highcharts);
Stock(Highcharts);
Vue.use(HighchartsVue);

Vue.use(CoreuiVue);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

// Интерцептор запросов для перевода на return login страницу, если cookie 'протухли'
const constantMock = window.fetch;
let addressTo;

router.afterEach((to) => {
  addressTo = to;
});

window.fetch = async function HandleInterceptor() {
  // eslint-disable-next-line prefer-rest-params
  return constantMock.apply(this, arguments).then((res) => {
    if (res.status === 401 && !addressTo) {
      router.push({
        name: 'Login'
      });
      return res;
    }

    if (res.status === 401 && (!addressTo.meta || !addressTo.meta.allowAnonymous)) {
      router.push({
        name: 'Login',
        query: {
          returnUrl: addressTo.fullPath
        }
      });
    }
    return res;
  });
};

new Vue({
  router,
  store,
  icons,
  render: (h) => h(App)
}).$mount('#app');
