import store from '../store';
import { getDetailsWithQuery } from '../api/common';

// авторизация пользователя
export const isAuth = (addressTo) => addressTo.matched.some((record) => record.meta.auth);

// делаем запрос на сервер с проверкой сесии
export const checkSession = (addressTo, currentUser) => (!!((isAuth(addressTo) && !currentUser)));

// проверяет есть ли ограничения по правам для перехода в раздел
export const roles = (addressTo) => addressTo.meta?.roles;

// проверяет есть ли права у пользователя для переход в раздел
export const checkHasPermission = async (addressTo) => {
  if (addressTo.meta?.access) {
    let isHasPermissons;
    try {
      const res = await getDetailsWithQuery('https://api.onlinelogic.ru/account/CheckPermissions', `element=${addressTo.meta.access}`);
      isHasPermissons = res.data;
    } catch (e) {
      console.log(e);
      isHasPermissons = false;
    }
    return isHasPermissons;
  }
  return true;
};

// функция перехода роутинга на страницу логина без query параметров адреса
export const goToLoginEmpty = (next) => next({
  name: 'Login'
});

// функция перехода на страницу логина с возвратным url в query параметре адреса
export const goToLoginWithReturn = (addressTo, next) => {
  next({
    name: 'Login',
    query: {
      returnUrl: addressTo.fullPath
    },
    replace: true
  });
};

// список адресов-исключений - при переходе на них не надо запрашивать список подключенных услуг
export const fullPathUrl = ['/registration', '/login', 'download', '/'];

// проверяет содержит ли новый адрес строку из fullPathUrl
export const checkUrl = (path) => fullPathUrl.some((el) => (el.includes(path)) || path === '/');

export const checkAvailableServices = (addressTo) => {
  if (addressTo.query.service) {
    const {
      services
    } = store.state.connectedServices.module;
    const servicesLower = Object.keys(services).map((el) => el.toLowerCase());
    const reportService = addressTo.query.service.toLowerCase();
    if (!servicesLower.includes(reportService)) {
      return false;
    }
    return true;
  }
  return true;
};
