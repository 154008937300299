const mutations = {
  SET_USER: (state, data) => {
    state.customerData = data;
    JSON.stringify(state.customerData.Bank);
  },

  SET_COMPANY: (state, data) => {
    state.companyData = data;
    JSON.stringify(state.companyData.Bank);
  },

  ADD_SERVICES: (state, services) => {
    state.serviceList = services;
  }
};

export default {
  mutations
};
