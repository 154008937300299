const mutations = {
  SAVE_STATUS: (state, servicesData) => {
    const entries = servicesData.map((el) => [el.Type, el.Status]);
    const services = Object.fromEntries(entries);
    state.services = services;
  }
};

export default {
  mutations
};
