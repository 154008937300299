import URL from '../../../basUrl';

const actions = {
  logout: async ({ commit }) => {
    try {
      await fetch(`${URL.AUTH}logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit('changeUser', {});
    } catch (e) {
      console.error(e);
    }
  },

  selectUser: async ({ commit }) => {
    try {
      const res = await fetch(`${URL.USERS}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const user = await res.json();
      commit('changeUser', user);
    } catch (e) {
      console.error(e);
    }
  }
};

export default {
  actions
};
