// import GET_RECEIVED_FINANCE_DOCUMENTS from '@/api/financeDocuments';

const actions = {
  sendRequestForTheDocuments: async (context, documents) => {
    context.commit('CHANGE_COMPUTED_DOCUMENTS', documents);
  },

  trimingRequestData: (context, formData) => context.commit('FORM_TRIMMING', formData)
};

export default {
  actions
};
