import {
  getDetailsWithQuery
} from '@/api/common';

const actions = {
  getCalls: async (context, {
    query,
    url,
    vm
  }) => {
    context.commit('SHOW_LOADING', true, {
      root: true
    });

    await getDetailsWithQuery(url, query)
      .then((result) => {
        // vm.$bvToast.show('danger-toast'); - toast bootstrap показывает юзеру оповещение с ошибкой
        const status = [200, 401, 404];
        if (!status.includes(result.status)) {
          context.commit('notifications/SAVE_MESSAGE', result.data.title, {
            root: true
          });
          vm.$bvToast.show('danger-toast');
          return;
        }

        context.commit('reports/SET_TABLE', result.data, { root: true });
      })
      .catch((e) => {
        console.error(e);
        context.commit('notifications/SAVE_MESSAGE', e, {
          root: true
        });
        vm.$bvToast.show('danger-toast');
      });

    context.commit('SHOW_LOADING', false, {
      root: true
    });
  }
};

export default {
  actions
};
