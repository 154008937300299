const state = {
  table: {},
  columns: [],
  rows: [],
  page: 1,
  requestData: {}
};

export default {
  state
};
