// Formating phone number from 'XXXXXXXXXXX' to 'X (XXX) XXX-XX-XX'
// PhoneNumberFormatted - key in phone.ServiceItems
// Full path: phone.ServiceItems.PhoneNumberFormatted
const formatPhone = (phone) => {
  const initMap = phone.ServiceItems;
  const phoneMap = initMap.map((el) => el.PhoneNumberFormatted.match(
    /(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/
  ));
  initMap.map((item, index) => {
    const newData = item;
    newData.PhoneNumberFormatted = `${phoneMap[index][1]} (${phoneMap[index][2]}) ${phoneMap[index][3]}-${phoneMap[index][4]}-${phoneMap[index][5]}`;
    return newData;
  });
};

// Mutations
const mutations = {
  SAVE_CUSTOMER_NUMBERS: (state, customerNumbers) => {
    state.userNumbers = customerNumbers;
    formatPhone(customerNumbers);
  },

  SAVE_CUSTOMER_LINES: (state, customerLines) => {
    state.userLines = customerLines;
    formatPhone(customerLines);
  },

  WRITE_NUMBERS: (state, selectedNumber) => {
    state.Numbers = selectedNumber;
  }
};

export default {
  mutations
};
