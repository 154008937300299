const mutations = {
  SET_FILTER: (state, params) => {
    state.filters = params;
  },

  SHOW_RECORDS: (state, recordsData) => {
    state.tableData = recordsData;
  },

  SET_PAGE: (state, pageNumber) => {
    state.filters.PageNumber = pageNumber;
  },

  COLLAPSE_TABLE: (state, value) => {
    state.tableData.forEach((el, index) => {
      /* Для корректного скрытия всех строк details в b-table bootstrap, надо,
         во всех объектах, из которых формируются строки таблицы,
         добавить/изменить значение ключа _showDetails на false,
         что и делает код под комментом
      */

      // eslint-disable-next-line no-underscore-dangle
      if (index !== value.index && state.tableData[index]._showDetails) {
        // eslint-disable-next-line no-underscore-dangle
        state.tableData[index]._showDetails = false;
      }
    });
  },

  CLEAR_TABLE: (state) => {
    state.tableData = [];
  }
};

export default {
  mutations
};
