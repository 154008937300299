const mutations = {
  SET_TABLE: (state, tableData) => {
    state.table = tableData;
  },

  CLEAR_TABLE: (state) => {
    state.table = [];
  }
};

export default {
  mutations
};
