const registration = {
  path: '/registration',
  name: 'registration',
  meta: {
    title: 'Регистрация',
    allowAnonymous: true
  },
  component: () => import('../views/TheRegistration.vue')
};

export default registration;
