const mutations = {
  changeUsers: (state, users) => {
    state.users = users;
  },

  changeUserRoles: (state, roles) => {
    state.userRoles = roles;
  }
};

export default {
  mutations
};
