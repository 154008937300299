const login = {
  path: '/login',
  name: 'Login',
  meta: {
    title: 'Авторизация',
    allowAnonymous: true
  },
  props: {
    default: true
  },
  component: () => import('../views/TheLogin.vue')
};

export default login;
