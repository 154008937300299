const mutations = {
  SAVE_MESSAGE: (state, message) => {
    state.message = message;
  },

  SET_NOTIFICATIONS: (state, message) => {
    state.notificationsList = [...message];
  }
};

export default {
  mutations
};
