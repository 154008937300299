const state = {
  regData: {
    ContactData: {
      FirstName: '',
      LastName: '',
      CompanyName: '',
      PhoneNumber: '',
      Email: ''
    },
    Login: '',
    Password: '',
    PasswordConfirm: '',
    Inn: '',
    Numbers: ['']
  },
  isFirstPage: true
};

export default {
  state
};
