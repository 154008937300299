const getters = {
  userData: (state) => state.userData,

  companyData: (state) => {
    const {
      companyData: {
        FullName,
        Inn,
        Kpp,
        Bank,
        BankCity,
        Rch,
        Bik,
        Kch,
        PostAddress,
        LegalAddress,
        PhysicalAddress,
        Email,
        Phone,
        Fax,
        Ogrn,
        Okpo,
        Okogu,
        Okato,
        Okved,
        ChiefFullNameGenitive,
        servicesList
      }
    } = state;

    return {
      name: {
        FullName,
        ShortName: FullName
      },
      details: [{
        name: 'ИНН:',
        value: Inn
      },
      {
        name: 'КПП:',
        value: Kpp
      },
      {
        name: 'Банк:',
        value: `${Bank} ${BankCity}`
      },
      {
        name: 'р/сч::',
        value: Rch
      },
      {
        name: 'БИК:',
        value: Bik
      },
      {
        name: 'к/сч:',
        value: Kch
      },
      {
        name: ' ',
        value: ''
      },
      {
        name: 'Почтовый адрес:',
        value: PostAddress
      },
      {
        name: 'Юридический адрес:',
        value: LegalAddress
      },
      {
        name: 'Адрес грузополучателя:',
        value: PhysicalAddress
      },
      {
        name: 'Электронный адрес:',
        value: Email
      },
      {
        name: 'Телефон:',
        value: Phone
      },
      {
        name: 'Факс:',
        value: Fax
      },
      {
        name: ' ',
        value: ' '
      },
      {
        name: 'ОГРН:',
        value: Ogrn
      },
      {
        name: 'ОКПО:',
        value: Okpo
      },
      {
        name: 'ОКОГУ:',
        value: Okogu
      },
      {
        name: 'ОКАТО:',
        value: Okato
      },
      {
        name: 'ОКВЭД:',
        value: Okved
      },
      {
        name: 'В лице директора ',
        value: ChiefFullNameGenitive
      }
      ],
      servicesList
    };
  },

  customerData: (state) => {
    const {
      companyData: {
        Inn,
        Kpp,
        Bank,
        Rch,
        Bik,
        Kch,
        PostAddress,
        LegalAddress,
        PhysicalAddress,
        Email,
        Phone,
        Fax,
        Ogrn,
        Okpo,
        Okogu,
        Okato,
        Okved,
        ChiefFullNameGenitive
      }
    } = state;

    return [{
      name: 'ИНН:',
      value: Inn
    },
    {
      name: 'КПП:',
      value: Kpp
    },
    {
      name: 'Банк:',
      value: Bank
    },
    {
      name: 'р/сч::',
      value: Rch
    },
    {
      name: 'БИК:',
      value: Bik
    },
    {
      name: 'к/сч:',
      value: Kch
    },
    {
      name: ' ',
      value: ''
    },
    {
      name: 'Почтовый адрес:',
      value: PostAddress
    },
    {
      name: 'Юридический адрес:',
      value: LegalAddress
    },
    {
      name: 'Адрес грузополучателя:',
      value: PhysicalAddress
    },
    {
      name: 'Электронный адрес:',
      value: Email
    },
    {
      name: 'Телефон:',
      value: Phone
    },
    {
      name: 'Факс:',
      value: Fax
    },
    {
      name: ' ',
      value: ' '
    },
    {
      name: 'ОГРН:',
      value: Ogrn
    },
    {
      name: 'ОКПО:',
      value: Okpo
    },
    {
      name: 'ОКОГУ:',
      value: Okogu
    },
    {
      name: 'ОКАТО:',
      value: Okato
    },
    {
      name: 'ОКВЭД:',
      value: Okved
    },
    {
      name: 'В лице директора ',
      value: ChiefFullNameGenitive
    }
    ];
  }
};

export default {
  getters
};
